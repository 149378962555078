var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',_vm._b({staticClass:"auction-bid",attrs:{"header-fill":_vm.activeColor,"right-button-icon-name":"close","right-button-to":_vm.name ? { name: 'auction-details', params: { name: _vm.name } } : { name: 'name-list' }}},'Page',
    _vm.amountStep && {
      leftButtonIconName: 'back',
      leftButtonTo: _vm.backTo,
    }
  ,false),[_c('template',{slot:"header"},[_c('Guide',{attrs:{"template":_vm.$t('name.bid.guide'),"fill":"neutral"}},[_c('template',{slot:"name"},[_vm._v(" "+_vm._s(_vm.internalName)+" ")])],2),_c('form',{attrs:{"id":_vm._uid},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.amountStep)?_c('AeInputName',{directives:[{name:"validate",rawName:"v-validate",value:('required|aens_name|aens_name_unregistered'),expression:"'required|aens_name|aens_name_unregistered'"}],attrs:{"autofocus":"","autocomplete":"off","error":_vm.errors.has('name'),"footer":_vm.errors.first('name'),"name":"name","header":_vm.$t('name.new.name'),"placeholder":_vm.$t('name.new.name-placeholder')},model:{value:(_vm.internalName),callback:function ($$v) {_vm.internalName=$$v},expression:"internalName"}}):_c('AeInputAmountCurrency',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          decimal: _vm.MAGNITUDE,
          min_value_currency: _vm.highestBid ? _vm.highestBid.multipliedBy(1.05).toString() : 0,
        }),expression:"{\n          required: true,\n          decimal: MAGNITUDE,\n          min_value_currency: highestBid ? highestBid.multipliedBy(1.05).toString() : 0,\n        }"}],attrs:{"error":_vm.errors.has('amount'),"footer":(_vm.errors.first('amount') && _vm.errors.first('amount').toString()) || ' ',"autofocus":"","name":"amount"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),(_vm.highestBid)?_c('DetailsAmountCurrency',{attrs:{"short":"","name":_vm.$t('name.bid.highest-bid'),"amount":_vm.highestBid}}):_vm._e(),(_vm.endsAt && _vm.topBlockHeight)?_c('DetailsField',{attrs:{"name":_vm.$t('name.bid.remaining-time'),"value":_vm.blocksToRelativeTime(_vm.endsAt - _vm.topBlockHeight)}}):_vm._e(),_c('AeButton',{attrs:{"fill":"secondary","spinner":_vm.busy,"form":_vm._uid,"disabled":_vm.errors.has('name') || !_vm.endsAt || _vm.busy}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }