import { render, staticRenderFns } from "./NameDetails.vue?vue&type=template&id=6c053e70&scoped=true"
import script from "./NameDetails.vue?vue&type=script&lang=js"
export * from "./NameDetails.vue?vue&type=script&lang=js"
import style0 from "./NameDetails.vue?vue&type=style&index=0&id=6c053e70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c053e70",
  null
  
)

export default component.exports